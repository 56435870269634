import classNames from "classnames";
import React from "react";

export const Price = ({ value, className }) => {
  if (isNaN(value)) return "";

  return (
    <span className={classNames(className)}>
      ${parseFloat(value).toFixed(2)}
    </span>
  );
};

export const formatPrice = (value) => {
  if (isNaN(value)) return "";

  const formattedValue = parseFloat(value).toFixed(2);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(formattedValue);
};

export const SupDollarSign = () => {
  return (
    <span
      className="inline-block text-[0.7em]"
      style={{
        transform: "translateY(-0.3em)",
      }}
    >
      $
    </span>
  );
};
