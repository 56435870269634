import { Link } from "gatsby";
import React from "react";
import LogoGrey from "../components/images/logo-grey";
import counties from "../data/counties";

const Footer = () => (
  <div className="bg-grey-lighter">
    <footer className="container mx-auto p-8">
      <div className="lg:flex mb-4 items-stretch">
        <div className="lg:mr-8 py-4 lg:flex-1">
          <div className="flex justify-center">
            <Link
              to="/"
              aria-label={`${process.env.COMPANY_NAME_SHORT} Home Page`}
            >
              <LogoGrey />
            </Link>
          </div>
          <a
            aria-label="Google Maps Link"
            href={process.env.GOOGLE_REVIEWS_PAGE}
            target="_blank"
            rel="noopener noreferrer"
            className="text-grey-darkest no-underline font-thin"
          >
            <div className="flex items-center justify-center mt-6">
              <div className="mr-3">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 60 60"
                  className="heroicon-map heroicon heroicon-sm"
                >
                  <path
                    className="heroicon-map-outer heroicon-component-accent heroicon-component-fill"
                    d="M20.05 13.02L15 11 0 17v40l15-6 15 6 15-6 15 6V17l-15-6-5.05 2.02c.03.32.05.65.05.98 0 5.52-10 20-10 20S20 19.52 20 14c0-.33.02-.66.05-.98z"
                  />
                  <path
                    className="heroicon-map-inner heroicon-component-fill"
                    d="M21.14 18.46L15 16 4 20v30l11-4 15 6 15-6 11 4V20l-11-4-6.14 2.46C36.23 24.97 30 34 30 34s-6.23-9.03-8.86-15.54z"
                  />
                  <path
                    className="heroicon-map-pin heroicon-component-accent heroicon-component-fill"
                    d="M30 34S20 19.52 20 14a10 10 0 1 1 20 0c0 5.52-10 20-10 20zm0-18a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  />
                  <path
                    className="heroicon-shadows"
                    d="M30 37S20 22.52 20 17c0-.58.05-1.15.14-1.7C21.37 21.5 30 34 30 34l.27-.4.73-1.09v3L30 37v20l15-6V11l-5.05 2.02c.03.32.05.65.05.98 0 4.98-8.14 17.25-9.73 19.6L30 37zM0 17l15-6v40L0 57V17z"
                  />
                  <path
                    className="heroicon-outline"
                    fillRule="nonzero"
                    d="M20.05 13.02a10 10 0 0 1 19.9 0L45 11l15 6v40l-15-6-15 6-15-6-15 6V17l15-6 5.05 2.02zm1.28 5.9L16 16.79v13.53l13 5.2v-3a69.99 69.99 0 0 1-.2-.31c-1.88-2.87-5.51-8.63-7.47-13.3zm-.5-1.26c-.32-.89-.57-1.72-.7-2.46L16 13.55v2.16l4.83 1.93v.02zM39.9 15.1c-.13.75-.38 1.61-.72 2.53L44 15.7v-2.16l-4.13 1.65.02-.09zm-1.2 3.75c-1.95 4.67-5.6 10.47-7.49 13.35l-.2.3v3.01l13-5.2V16.8l-5.33 2.13.02-.06zm-5.42 6.2a66.36 66.36 0 0 0 3.34-6.4C37.54 16.52 38 14.92 38 14a8 8 0 1 0-16 0c0 .93.46 2.53 1.4 4.65.9 2.06 2.1 4.3 3.33 6.42 1.18 2.03 2.36 3.9 3.27 5.32.91-1.42 2.09-3.29 3.27-5.32zM46 13.57v2.15l10 4v14.61l2 .8V18.35l-12-4.8zM14 15.7v-2.16l-12 4.8v16.77l2-.8V19.71l10-4zM5 33.92l9-3.6V16.8l-9 3.6v13.53zm-1 2.56l-2 .8v16.77l12-4.8v-2.16l-9 3.6-1 .4V36.48zM14 46V32.48l-9 3.6V49.6l9-3.6zm2 1.08v2.16l13 5.2v-2.16l-13-5.2zm13 4.12V37.68l-13-5.2V46l13 5.2zm2 1.08v2.16l13-5.2v-2.16l-13 5.2zm13-6.28V32.48l-13 5.2V51.2l13-5.2zm2 1.08v2.16l12 4.8V37.28l-2-.8v14.61l-1-.4-9-3.6zm9-11.01l-9-3.6V46l9 3.6V36.08zm0-2.16V20.4l-9-3.6v13.53l9 3.6zM30 17a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm2-4a2 2 0 1 0-4 0 2 2 0 0 0 4 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="tracking-wide">
                  {process.env.ADDRESS_LINE_1}
                  <br />
                  {process.env.ADDRESS_LINE_2}
                </p>
              </div>
            </div>
          </a>
          <a
            aria-label="Call us link"
            href={`tel:${process.env.PHONE_NUMBER}`}
            className="text-grey-darkest no-underline font-thin"
          >
            <div className="mt-6 text-center">
              <div className="tracking-wide">{process.env.PHONE_NUMBER}</div>
            </div>
          </a>
        </div>
        <div className="mt-4 py-4 lg:flex-1 lg:ml-8 leading-loose text-center lg:text-right">
          <h3 className="text-grey-darkest leading-tight mb-1">Products</h3>
          <Link
            to="/window-well-covers/"
            className="font-light text-grey-darker no-underline block"
          >
            Window Well Covers
          </Link>
          <Link
            to="/window-well-liners/"
            className="font-light text-grey-darker no-underline block"
          >
            Window Well Liners
          </Link>
          <Link
            to="/quote-builder/"
            className="font-light text-grey-darker no-underline block mt-4"
          >
            Quote Builder
          </Link>
        </div>
        <div className="mt-4 py-4 lg:flex-1 lg:ml-8 leading-loose text-center lg:text-right">
          <h3 className="text-grey-darkest leading-tight mb-1">Accessories</h3>
          <Link
            to="/ez-lift-systems/"
            className="font-light text-grey-darker no-underline block"
          >
            EZ Lift System
          </Link>
          <Link
            to="/window-well-ladders/"
            className="font-light text-grey-darker no-underline block"
          >
            Window Well Ladders
          </Link>
          <Link
            to="/window-well-locks/"
            className="font-light text-grey-darker no-underline block"
          >
            Window Well Locks
          </Link>
        </div>
        <div className="mt-4 py-4 lg:flex-1 lg:ml-8 leading-loose text-center lg:text-right">
          <h3 className="text-grey-darkest leading-tight mb-1">About</h3>
          <Link
            to="/faq/"
            className="font-light text-grey-darker no-underline block"
          >
            FAQs
          </Link>
          <Link
            to="/blog/"
            className="font-light text-grey-darker no-underline block"
          >
            Blog
          </Link>
          <Link
            to="/contact-us/"
            className="font-light text-grey-darker no-underline block"
          >
            Contact Us
          </Link>
          <Link
            to="/service-areas/"
            className="font-light text-grey-darker no-underline block"
          >
            Service Areas
          </Link>
          <Link
            to="/privacy-policy/"
            className="font-light text-grey-darker no-underline block"
          >
            Privacy Policy
          </Link>
          <Link
            to="/return-policy/"
            className="font-light text-grey-darker no-underline block"
          >
            Return Policy
          </Link>
          <Link
            to="/terms-and-conditions/"
            className="font-light text-grey-darker no-underline block"
          >
            Terms &amp; Conditions
          </Link>
        </div>
        <div className="mt-4 py-4 lg:flex-1 lg:ml-8 leading-loose text-center lg:text-right">
          <h3 className="text-grey-darkest leading-tight mb-1">
            Counties We Serve
          </h3>
          {counties.map((county) => {
            return (
              <Link
                className="font-light text-grey-darker no-underline block"
                key={county.link}
                to={county.link}
              >
                {county.text}
              </Link>
            );
          })}
        </div>
      </div>
    </footer>
    <div className="bg-grey-darkest text-grey py-3">
      <p className="font-light text-sm text-center">
        &copy; {new Date().getFullYear()} {process.env.COMPANY_NAME}{" "}
        <span className="hidden md:inline md:px-2">|</span>{" "}
        <span className="block md:inline">
          Web development by{" "}
          <a
            className="text-grey no-underline"
            href="https://bootpackdigital.com/"
          >
            Bootpack Digital, LLC
          </a>
        </span>
      </p>
    </div>
  </div>
);

export default Footer;
